import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, Input, Checkbox, Tooltip, TextField } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/system';
import axiosInstance from '../../services/axiosConfig';
import { color } from '../../colors';
import { NumberInputField } from "./input-fields/number-input-field/number-input-field.component";
import ThreeDeeViewer from './ThreeDeeViewer';

export const NonEditableTextarea = styled(InputBase)({
  // pointerEvents: 'none',
  userSelect: 'text',
  cursor: 'text',
  border: `1px solid ${color.Yambo_Purple_Stroke}`,
  borderRadius: '4px',
  padding:'8px',
});

const MAX_IMAGES_PER_ROW = 20;
export const NodeImageList = ({ images, selected, setSelected, container }) => {

  const maxSteps = images.length;
  const [zoomPercentage, setZoomPercentage] = useState(100);
  const [scale, setScale] = useState(1);
  const imgRef = useRef(null);
  const resetTransformRef = useRef(null);
  const [transformKey, setTransformKey] = useState(0);


  const imagesPerRow = Math.min(images.length, MAX_IMAGES_PER_ROW);
  const rows = Math.ceil(images.length / imagesPerRow);
  const isSingleRow = images.length <= imagesPerRow;

  const resetZoom = async () => {
    if (resetTransformRef.current) {
      // console.log("reseting zoom");
      await resetTransformRef.current.resetTransform();
      setScale(1);
    }
  };

  useEffect(()=>{
    if (imgRef.current && images.length > 0) {
      const renderedWidth = imgRef.current.offsetWidth;
      const initialZoom = (renderedWidth / images[selected].width) * 100;
      setZoomPercentage(Math.round(initialZoom));
    }
  },[]);

  const calculateZoomPercentage = useCallback((newScale) => {
    if (imgRef.current && images.length > 0) {
      const renderedWidth = imgRef.current.offsetWidth * newScale;
      const zoomPercent = (renderedWidth / images[selected].width) * 100;
      setZoomPercentage(Math.round(zoomPercent));
    }
  }, [images, selected]);

  const handleNext = async () => {
    if(container === 'gallery') {
      await resetZoom();
    }
    setTransformKey((prev) => prev + 1);
    setSelected((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };
  
  const handleBack = async () => {
    if(container === 'gallery')
      await resetZoom();
    setTransformKey((prev) => prev + 1);
    setSelected((prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps);
  };

  const handleArrowUp = async () => {
    if(container === 'gallery') {
      await resetZoom();
    }
    setTransformKey((prev) => prev + 1);
    setSelected((prevActiveStep) => {
      return prevActiveStep - imagesPerRow >= 0 ? prevActiveStep - imagesPerRow : prevActiveStep;
    });
  };

  const handleArrowDown = async () => {
    if(container === 'gallery') {
      await resetZoom();
    }
    setTransformKey((prev) => prev + 1);
    setSelected((prevActiveStep) => {
      return prevActiveStep + imagesPerRow < maxSteps ? prevActiveStep + imagesPerRow : prevActiveStep;
    });
  };



  const handleImageLoad = () => {
    // Calculate the zoom percentage when the image is fully loaded
    if (imgRef.current && images.length > 0) {
      const initialZoom = (imgRef.current.offsetWidth / images[selected].width) * 100;
      setZoomPercentage(Math.round(initialZoom));
    }
  };

  useEffect(()=>{
    // calcualte the zoom percentage on zoom change
    if(scale){
      calculateZoomPercentage(scale);
    }
  },[scale]);

  useEffect(() => {
    // Reset the zoom scale when the selected image changes
    if (resetTransformRef.current) {
      resetTransformRef.current.resetTransform();
      setScale(1);
    }
  }, [selected]);

  useEffect(()=>{
    const handleKeydown = (event) => {
      if(event.key === 'ArrowRight'){
        event.stopPropagation();
        if(container === 'gallery')
          handleNext();
      }
      if(event.key === 'ArrowLeft'){
        event.stopPropagation();
        if(container === 'gallery')
          handleBack();
      }
      if(event.key === 'ArrowUp'){
        event.stopPropagation();
        if(container === 'gallery')
          handleArrowUp();
      }
      if(event.key === 'ArrowDown'){
        event.stopPropagation();
        if(container === 'gallery')
          handleArrowDown();
      }
    };
    window.addEventListener('keydown', handleKeydown);
    
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  },[]);

  return (
    <Box sx={ {
      display:'flex',
      flexDirection:'column',
      width:'100%',
      height:'100%',
      position:'relative',
    } }
    >
      <Box
        id='image-list-container'
        sx={ {
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          height: maxSteps > 1 && container === 'gallery' ? '85%':'100%',
          width:'100%',
          mb: container === 'node' ? 1 : 0,
        } }
      >
        <Box
          id='image-list-wrapper'
          ref={ imgRef }
          sx={ {
            maxWidth: container === 'node' ? 400: 'none',
            width: container === 'node' ? 'auto':  '90%',
            height:container === 'node' ? 'auto': '85%',
            margin: 'auto',
          } }
          onClick={ (event)=> event.stopPropagation() }
        >
          {images.map((img, index) => (
            <Box key={ index } style={ { width:'100%', height:'100%', display: index === selected ? 'block' : 'none' } }>
              {img.type && img.type.includes('image') && <>
                {container === 'node' ?
                  ( <>
                    <img
                      src={ img.url }
                      draggable="false"
                      style={ { width: '100%', height:'100%', display:'block', position:'relative' } }
                    />
                    <Typography
                      variant="caption"
                      sx={ { fontWeight:'bold', fontSize:'10px', position:'absolute', top:5, left:5, textShadow:'0px 0px 2px black' } }
                    >
                      {img.width} X {img.height}
                    </Typography>
                  </>):
                  (
                    <>
                      <TransformWrapper
                        style={ { width:'100%', height:'100%' } }
                        centerZoomedOut={ true }
                        limitToBounds={ true }
                        minScale={ 1 }
                        pinch={ {
                          step:100, // Increase this value for more sensitivity
                        } }
                        onZoom={ (ref) => {
                          setScale(ref.state.scale);
                        } }
                        ref={ resetTransformRef }
                        key={ transformKey }
                      >
                        <TransformComponent style={ { width:'100%', height:'100%' } }>
                          <img className="media-container"  onLoad={ handleImageLoad }  src={ img.url } draggable="false" style={ { maxWidth: '100%', maxHeight:'100%', display:'block', margin:'auto' } } />

                        </TransformComponent>
                      </TransformWrapper>
                    </>
                  )
                }
              
              </>
              }
              {img.type && img.type.includes('video') &&
                  <>
                    <video crossOrigin="anonymous" draggable="false" src={ img.url } controls loop style={ { width: '100%', height:'100%', display:'block', position:'relative' } } />
                    { container === 'node' &&
                        <Typography
                          variant="caption"
                          sx={ { fontWeight:'bold', fontSize:'10px', position:'absolute', top:5, left:5, textShadow:'0px 0px 2px black' } }>{img.width} X {img.height}
                        </Typography>
                    }
                  </>
              }
              {index === selected && img.type && img.type.includes('3D') && ( // this is unique case - in order to completely re-render the 3D canvas (to solve canvas size issue)
                <ThreeDeeViewer key={ img.url } objUrl={ img.url } containerSize={ container === 'gallery'? { w: 600, h: 600 }:null } />
              )}
              {img.type && img.type.includes('audio') &&
                <Box
                  sx={ { height:'80px', width:'300px' } }
                >
                  <audio crossOrigin="anonymous" draggable="false" src={ img.url } controls loop style={ { width: '100%', display:'block' } } />
                </Box>
              }
              {img.type && img.type.includes('text') && <Box sx={ { background:color.Yambo_Purple_Dark } }>
                <Typography variant='caption'>Result</Typography>
                <Box sx={ { color:'white', fontSize:'12px', fontStyle:'italic', width:'300px' } }>
                    
                  <NonEditableTextarea
                    fullWidth
                    value={ img.value }
                    multiline={ true }
                    readOnly={ true }
                  />
                </Box>
              </Box> }
              {container === 'gallery' && (img.type === 'image' || img.type === 'video') &&
            <Box id='file-info-container'
              sx={ {
                position:'absolute',
                top:20,
                left:20,
                display:'flex',
                flexDirection:'column',
              } }
            >
              <Typography variant='caption'>Info</Typography>
              <Typography
                variant="caption"
                sx={ {
                  fontWeight:'bold',
                  fontSize:'10px',
                    
                  textShadow:'0px 0px 2px black',
                } }
              >
                {img.width} X {img.height} {container === 'gallery' && img.type === 'image'? `| ${zoomPercentage}%`:``}
              </Typography>
            </Box>
              }
            </Box>
          ))}
          {/* Node carousel */}
          {container === 'node' && maxSteps > 1 && (
            <Box
              sx={ {
                position: 'absolute',
                bottom: 7,
                width:'100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#0008',
              } }
              onMouseEnter={ (e)=> e.stopPropagation() }
            >
              <Button size="small" onClick={ handleBack }>
                <KeyboardArrowLeft />
              </Button>
              {/* Custom text indicator without dots */}
              <Typography variant="body2" color="common.white">
                {`${selected + 1}/${maxSteps}`}
              </Typography>
              <Button size="small" onClick={ handleNext }>
                <KeyboardArrowRight />
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {container === "gallery" && maxSteps > 1 && (
        <Box
          sx={ {
            width:'100%',
            height:'15%',
       
            position:'relative',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            pb:4,
          } }
        >
          <Box
            id='media-gallery-carousel'
            sx={ {
              width:'80%',
              height:'100%',
              margin:'auto',
              // position: 'relative',
              // bottom:0, 
            } }
            onClick={ (event)=> event.stopPropagation() }
          >
            {Array.from({ length: rows }).map((_, rowIndex) => (
              <Box
                key={ rowIndex }
                sx={ {
                  height: `${100 / rows}%`, // Distribute height evenly among rows
                  width: '100%',
                  display: 'flex',
                  justifyContent: isSingleRow ? 'center' : 'flex-start',
                } }
              >
                {images.slice(rowIndex * imagesPerRow, (rowIndex + 1) * imagesPerRow).map((file, index) => {
                  const globalIndex = rowIndex * imagesPerRow + index;
                  
                  return (
                    <Box
                      key={ globalIndex }
                      sx={ {
                        height:'100%',
                        width: `${100 / imagesPerRow}%`, // Distribute width evenly
                        border: selected === globalIndex ? `1px solid ${color.Yambo_Purple}` : 'none',
                      } }
                      onClick={ () => setSelected(globalIndex) }
                      id={ `carousel-item-${globalIndex}` }
                    >
            
                      {file.thumbnailUrl ? (
                        <img
                          src={ file.thumbnailUrl }
                          alt={ `Image ${globalIndex}` }
                          style={ {
                            display: 'block',
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                            filter: selected !== globalIndex ? 'grayscale(100%) brightness(60%)' : 'none',
                            cursor:'pointer',
                          } }
                        />
                      ):(
                        <Box sx={ { height:'100%' ,display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' } }>
                          <Typography>Result {globalIndex + 1}</Typography>
                          <Typography variant='caption'>No Preview Available</Typography>
                        </Box>
                      )
                      }
                    </Box>
                  );
                })}
              </Box>))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const getImageDimensions = async (image) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
  
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      resolve({ width, height });
    };
  
    img.onerror = (error) => {
      reject(error);
    };
  
    img.src = image;
  });
};

export const getFileDimensions = (file, type) => {
  return new Promise((resolve, reject) => {
    // For images
    if (type === 'image') {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = reject;
      img.src = file;
    }
    // For videos
    else if (type === 'video') {
      const video = document.createElement('video');
      video.onloadedmetadata = () => {
        resolve({ width: video.videoWidth, height: video.videoHeight });
      };
      video.onerror = reject;
      video.src = file;
    } else {
      reject(new Error('Unsupported file type'));
    }
  });
};
  
  
export const extractInputSchemaDetails = (jsonData) => {

  const schemas = jsonData.openapi_schema?.components?.schemas || {};
  const inputSchema = schemas.Input?.properties || {};
  const requiredKeys = schemas.Input?.required || [];
  const extractedDetails = {};
  
  const resolveRef = (ref) => {
    const refPath = ref.split('/');
    const refSchemaName = refPath[refPath.length - 1];
    
    return schemas[refSchemaName];
  };
  
  Object.entries(inputSchema).forEach(([propName, propInfo]) => {
    let propDetails = { type: propInfo.type };

    // support array of diffrent types
    if(propInfo.type === 'array'){
      propDetails.array_type = propInfo.items.type;
    }

    // Include default values
    if (propInfo.default !== undefined) {
      propDetails.default = propInfo.default;
    }
  
    // Handle enums directly within the property
    if (propInfo.enum) {
      propDetails.options = propInfo.enum;
    }
  
    // Handle min and max for numbers and integers
    if (propInfo.type === 'number' || propInfo.type === 'integer') {
      if (propInfo.minimum !== undefined) {
        propDetails.min = propInfo.minimum;
      }
      if (propInfo.maximum !== undefined) {
        propDetails.max = propInfo.maximum;
      }
      if(propInfo.minimum === undefined && propInfo.maximum === undefined){ // I assume it's not a slider but an input box
        propDetails.type = 'input';
      }
    }
  
    if(propName === 'Seed' || propName === 'seed'){
      propDetails.type = 'seed';
    }
    // Resolve references to other schemas and extract details
    if (propInfo['allOf']) {
      const referencedSchema = resolveRef(propInfo['allOf'][0]['$ref']);
      // If the referenced schema is an enum, extract its options
      if (referencedSchema.enum) {
        propDetails.ref = propName;
        propDetails.options = referencedSchema.enum;
        propDetails.type = "enum";
      }
      // Extend to include other details from the referenced schema as needed
    }

    /// fix name to be presentable
    function capitalizeWords(str) {
      return str.replace(/_/g, ' ') // Replace underscores with spaces
        .split(' ') // Split the string into words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
        .join(' '); // Rejoin the words into a single string
    }
    /// title
    propDetails.title = capitalizeWords(propName);
    /// description
    propDetails.description = propInfo['description'];
    if(propName === 'seed' || propName === 'Seed'){
      propDetails.description = 'Seed value for random number generator. Uncheck for reproducible results.';
    }
    /// order 
    propDetails.order = propInfo['x-order'];
    propDetails.required = requiredKeys.includes(propName) ? true : false;
    propDetails.format = propInfo['format'];

    // normalize seed names
    if(propName === 'seed' || propName === 'Seed'){
      propName = 'seed';
    }
    extractedDetails[propName] = propDetails;
  });
  
  const sortedDetailsArray = Object.entries(extractedDetails).sort((a, b) => {
    const orderA = a[1].order || 0; // Provide a default order if undefined
    const orderB = b[1].order || 0; // Provide a default order if undefined
    
    return orderA - orderB;
  });
  
  // Convert the sorted array back into an object
  const sortedExtractedDetails = sortedDetailsArray.reduce((acc, [key, value]) => {
    acc[key] = value;
    
    return acc;
  }, {});
  
  return sortedExtractedDetails;
};
  
export const renderDynamicField = (params, key, property, handleChange) => {
  // const value = dynamicFields[key];
  const value = params[key];
  switch (property.type) {
    case "integer":
    case "number":
      return (
        <NumberInputField
          key= { `${key}-numberInputField` }
          inputKey={ key }
          title={ property.title }
          description={ property.description }
          value={ value }
          disabled={ property.expose }
          min={ property.min }
          max={ property.max }
          type={ property.type }
          onChange={ handleChange }
        />
      );
    case "input":
      return (
        <Box key={ `${key}-textfield` } sx={ { mb:2 } }>
          <Typography variant="caption" className="property-title" sx={ { mr:.5 } }>{property.title}</Typography>
          <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
            <HelpOutlineIcon fontSize="10px" />
          </Tooltip>
          <Input
            key={ `${key}-textfield` }
            fullWidth
            // label={key}
            value={ value }
            size="small"
            inputProps={ { type: 'number' } }
            onChange={ (e) => handleChange(key, parseInt(e.target.value)) }
          />
        </Box>
      );
    case "enum":
      return (
        <Box key={ `${key}-enum` } sx={ { mb:2 } }>
          <Typography variant="caption" className="property-title" sx={ { mr:.5 } }>{property.title}</Typography>
          <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
            <HelpOutlineIcon fontSize="10px" />
          </Tooltip>
          <FormControl fullWidth sx={ { mt:1 } }>
            {/* <InputLabel id={`${key}-label`}>{property.title}</InputLabel> */}
            <Select
              labelId={ `${key}-label` }
              id={ key }
              value={ value }
              // label={property.title}
              onChange={ (e) => handleChange(key, e.target.value) }
              size="small"
            >
              {property.options.map((option) => (
                <MenuItem key={ option } value={ option }>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      );
        
    case "boolean":
      return (
        <Box key={ `${key}-enum` } sx={ { mb:2, display:'flex', flexDirection:'row', alignItems:'center' } } >
          <FormControl >
            <Checkbox inputProps={ { 'aria-label': 'Checkbox' } } checked={ value } onChange={ (e) => handleChange(key, e.target.checked) } />
          </FormControl>
          <Typography variant="caption" className="property-title" sx={ { mr:.5 } }>{property.title}</Typography>
          <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
            <HelpOutlineIcon fontSize="10px" />
          </Tooltip>
                  
        </Box>
      );
    case "seed":
      return (
        <Box key={ `${key}-enum` } sx={ { mb:2 } } >
          <Typography variant="caption" className="property-title" sx={ { mr:.5 } }>{property.title}</Typography>
          <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
            <HelpOutlineIcon fontSize="10px" />
          </Tooltip>
          <Box sx={ { display:'flex', flexDirection:'row', alignItems:'center' } }>
            <FormControl >
              <Checkbox inputProps={ { 'aria-label': 'Checkbox' } } checked={ value?.isRandom }
                onChange={ (e) =>
                  handleChange(
                    key,
                    {
                      isRandom: e.target.checked,
                      seed: value.seed,
                    },
                  ) }
              />
            </FormControl>
            <Typography variant="caption" className="property-title" sx={ { mr:.5 } }>Random</Typography>
                              
            <Input
              value={ value?.seed }
              inputProps={ { type: 'number' } }
              size="small"
              onChange={ (e) => handleChange(key, { isRandom: value?.isRandom, seed: e.target.value }) }
              disabled={ value?.isRandom }
              sx={ { ml:1 } }
            />
          </Box>
        </Box>
      );
    case "array":
      return (
        <Box key={ `${key}-text` } sx={ { mb:2 } } >
          <Typography variant="caption" sx={ { mr:.5 } }>{property.title}</Typography>
                    
          <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
            <HelpOutlineIcon fontSize="10px" />
          </Tooltip>
          <TextField
            fullWidth
            id={ key }
            multiline
            value={ Array.isArray(value) ? value.filter(Boolean).join(', ') : '' }
            rows={ 3 }
            onChange={ (e) => {
              const newValue = e.target.value.split(',').map((item) => item.trim()).filter(Boolean);
              console.log("newValue", newValue);
              handleChange(key, newValue);
            } }
            size="small"
          />
          <Typography variant="caption">Use comma for multiple entries </Typography>
                    
        </Box>
      );
    default:
      return null;
  }
};


export function sanitizeNodes(nodes) {
  const sanitizedNodes = nodes.map((node) => {
    if(node.data.output){
      for (const [name, conf] of Object.entries(node.data.output)) {
        if (conf?.type === "image" && conf?.url.includes("data:image/png;base64")) {
          node.data.output[name].url = "";
        }
      }
    }

    if(node.data.input){
      for (const [name, conf] of Object.entries(node.data.input)) {
        if (conf?.type === "image" && conf?.url.includes("data:image/png;base64")) {
          node.data.input[name].url = "";
        }
      }
    }

    return node;
  });

  return sanitizedNodes;
}

function performMatteChoke(imageData, amount) {
  const width = imageData.width;
  const height = imageData.height;
  const data = new Uint8ClampedArray(imageData.data);
  const result = new Uint8ClampedArray(data.length);

  const threshold = 128;
  const iterations = Math.abs(amount);
  const expand = amount > 0;

  for (let i = 0; i < iterations; i++) {
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const idx = (y * width + x) * 4;
        let sum = 0;
        let count = 0;

        // Check neighboring pixels
        for (let dy = -1; dy <= 1; dy++) {
          for (let dx = -1; dx <= 1; dx++) {
            const nx = x + dx;
            const ny = y + dy;
            if (nx >= 0 && nx < width && ny >= 0 && ny < height) {
              const nidx = (ny * width + nx) * 4;
              sum += data[nidx] > threshold ? 1 : 0;
              count++;
            }
          }
        }

        // Determine if the pixel should be white or black
        const shouldBeWhite = expand ? (sum > 0) : (sum === count);
        const value = shouldBeWhite ? 255 : 0;

        // Set the result pixel
        result[idx] = result[idx + 1] = result[idx + 2] = value;
        result[idx + 3] = 255; // Alpha channel
      }
    }

    // Copy result back to data for next iteration
    data.set(result);
  }

  return new ImageData(result, width, height);
}

/// another options
export function matteChoker2(base64Image, amount) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const chokedImageData = performMatteChoke(imageData, amount);

      ctx.putImageData(chokedImageData, 0, 0);
      resolve(canvas.toDataURL());
    };
    img.onerror = reject;
    img.src = base64Image;
  });
}

// bria register image
export const registerImageUtil = async (url) => {
  const res = await axiosInstance.post("/v1/models/br/run/masks/register", { url });
  
  return res.data.visualId;
};

export const getOS = () => {
  const platform = window.navigator.platform.toLowerCase();
  if (platform.includes('mac')) {
    return 'Mac';
  } else if (platform.includes('win')) {
    return 'Windows';
  }
  
  return 'Other';
};

export const flattenMenuItems = (items, searchTerm) => {
  let flatItems = [];
  const uniqueIds = new Set(); // Set to keep track of unique IDs

  const searchAndFlatten = (item) => {
    Object.keys(item).forEach((key) => {
      const currentItem = item[key];
      if (
        (currentItem.displayName.toLowerCase().includes(searchTerm.toLowerCase()) ||
         (currentItem?.alias && currentItem?.alias.includes(searchTerm.toLowerCase()))
        ) &&
        !currentItem.children &&
        !uniqueIds.has(currentItem.id) // Check if ID is already in the Set
      ) {
        flatItems.push(currentItem);
        uniqueIds.add(currentItem.id); // Add ID to Set
      }
      if (currentItem.children) {
        searchAndFlatten(currentItem.children);
      }
    });
  };

  // Start the search and flattening process
  searchAndFlatten(items);

  return flatItems;
};

export const cleanParamsForSaving = (params) => {
  const cleanParams = _.cloneDeep(params)
  if(cleanParams && cleanParams.seed){
    cleanParams.seed = cleanParams.seed.seed;
  }
  // if(cleanParams){
  //   for (const [name, conf] of Object.entries(cleanParams)){
  //     if (typeof conf === 'string' && conf?.url.includes("data:image/png;base64")) {
  //       cleanParams[name].url = "";
  //     }
  //   }
  // }
  
  return cleanParams;
}

export function rgbaToRgb(imageUrl, format = "jpeg") {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // Enable cross-origin if needed
    img.src = imageUrl;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set the canvas size to match the image
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image on the canvas
      ctx.drawImage(img, 0, 0);

      // Convert the canvas back to a data URL in the desired format
      const rgbImageUrl = canvas.toDataURL(`image/${format}`);

      // Cleanup: remove the canvas
      canvas.remove();

      resolve(rgbImageUrl);
    };

    img.onerror = () => {
      reject(new Error("Failed to load image."));
    };
  });
}
