/* eslint-disable no-template-curly-in-string */
import KEYS from './keys';

const EN_TEXT = {
  [KEYS.PAYMENTS.SUCCESS_MESSAGE]: '🎉&nbsp;Credits charged!&nbsp;🎉',
  [KEYS.NAVIGATION_DRAWER.BUY_MORE]: 'Buy More',
  [KEYS.DASHBOARD_BANNER.MESSAGE]: '🚀 🎥 Luma Dream Machine is now officially on Weavy 🎥 🚀',
  [KEYS.DASHBOARD_BANNER.CTA]: 'Check it out!',
  [KEYS.UPGRADE_MODAL.TITLE]: 'Buy more credits for Runway and Luma',
  [KEYS.UPGRADE_MODAL.CTA]: 'Learn more...',
  [KEYS.MODEL_NODE.ERROR_REQUIRED]: 'Missing inputs: ',
  [KEYS.MODEL_NODE.ERROR_IMAGE_INPUT]: 'Cannot read input image',
  [KEYS.RECIPE_MAIN.ERROR_SAVE]: 'Unable to save your work. Please contact support',
};

export default EN_TEXT;
