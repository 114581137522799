import React, { useEffect, useState, useRef } from "react";
import { usePostHog, useFeatureFlagEnabled } from "posthog-js/react";
import { Box, IconButton, Typography, CircularProgress, TextField, Button, Tooltip, Skeleton, Link } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { color } from "../../../colors";
import { useUserRole } from '../UserRoleContext';
import axiosInstance from "../../../services/axiosConfig";
import { CreditsContext } from "../../../services/CreditsContext";


function SlideTransition(props) {
  return <Slide { ...props } direction="down" />;
}

function FlowNavbar (props) {

  const { credits, openUpgradeModal } = React.useContext(CreditsContext);
  const posthog = usePostHog();
  const {
    isSaving,
    recipeData,
    recipeId,
    setSelectOnDrag,
    gotoDashboard,
    savingBeforeExit,
    duplicateRecipe,
    setShowBuyMore,
    openDesignApp
  } = props;

  const fakeDesignAppFlag = useFeatureFlagEnabled('fake_design_app');

  const [isEditingName, setIsEditingName] = useState(false);
  const [newRecipeName, setNewRecipeName] = useState();
  // const [zoomValue, setZoomValue] = useState(1);
  const oldRecipeName = useRef(null);
  const [activeTool, setActiveTool] = useState("select");
  const nameRef = useRef(null);

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const role = useUserRole();

  useEffect(()=>{
    if(recipeData && recipeData.name)
      setNewRecipeName(recipeData.name);
  },[recipeData?.name]);

  const goToDashboard = () => {
    gotoDashboard();
  };

  const renameRecipe = async (newName) => {

    if(newName === recipeData.name) return;

    let updatedRecipe = null;
    updatedRecipe = { ...recipeData, name:newName };
    try{
      await axiosInstance.put(`/v1/recipes/${recipeId}`, updatedRecipe);
    } catch(error){
      console.log("error creating recipe: ", error);
    }
  };

  useEffect(() => {
    if (isEditingName && nameRef.current) {
      oldRecipeName.value = newRecipeName;
      nameRef.current.focus();
      const input = nameRef.current.querySelector('input');
      if (input) {
        input.select();
      }
    }
  }, [isEditingName]);

  const handleRecipeNameChange = (e) => {
    setNewRecipeName(e.target.value);
  };

  const handleRecipeNameBlur = () => {
    renameRecipe(newRecipeName);
    setIsEditingName(false);
  };

  const handleRecipeNameEnter = (e) => {
    if(e.key === 'Enter'){
      e.preventDefault();
      renameRecipe(newRecipeName);
      setIsEditingName(false);
    }
    if(e.key === 'Escape'){
      e.preventDefault();
      setNewRecipeName(oldRecipeName.value);
      setIsEditingName(false);
    }
  };

  ///// MENU
  const handleClickPointer = () => {
    setActiveTool("select");
    setSelectOnDrag(true);
  };

  const handleClickHand = () => {
    setActiveTool("pan");
    setSelectOnDrag(false);
  };

  // const handleZoomChange = (newValue) => {
  //   setZoomValue(newValue);
  // };

  const handleShareLink = async () => {
    posthog.capture('clicked_share');
    const updatedRecipe = { public:true };
    try{
      await axiosInstance.put(`/v1/recipes/${recipeId}`, updatedRecipe);
      await navigator.clipboard.writeText(window.location.href);
      setIsLinkCopied(true);
      setTimeout(()=>setIsLinkCopied(false), 2000);
    } catch(error) {
      console.log("error creating recipe: ", error);
    }
  };

  const saveToMyFiles = async () => {
    try{
      await duplicateRecipe(recipeId);
    } catch(error){
      console.log("error duplicating recipe: ", error);
    }
  };

  //// upgrade modal
  const handleBuyMoreClick = () => {
    localStorage.setItem('lastPageBeforePayment', window.location.href);
    setShowUpgradeModal(true);
  }
  
  return(
    <Box id="flow-navbar" style={ { display:'flex', justifyContent:'space-between', alignItems:'center', height:'48px' } }>
      <Box id="navigation-tools" sx={ { display:'flex', flexDirection:'row', alignItems:'center' } }>
        {!savingBeforeExit?
          (<IconButton onClick={ goToDashboard }>
            <ChevronLeftIcon />
          </IconButton>
          ):(
            <Box sx={ { width:'40px', display:'flex', alignItems:"center", justifyContent:'center' } }>
              <CircularProgress size={ 16 } sx={ { color:color.Yambo_Text_On_Dark } } />
            </Box>
          )}
        <IconButton onClick={ handleClickPointer } className={ activeTool==="select"? 'active': '' }>
          <img src='/icons/pointer.svg' width='13px' />
        </IconButton>
        <IconButton onClick={ handleClickHand } className={ activeTool==="pan"? 'active': '' }>
          <i className="fa-regular fa-xs fa-hand" style={ { fontSize:'14px' } }></i>
        </IconButton>
        { role !== 'editor' && <Box sx={ { ml:1 } }>
          <Button onClick={ saveToMyFiles } variant="contained" size="small" sx={ { height:'auto' } } className="save-this-button" color="weavy_cta_secondary">
                            Duplicate to My Files
          </Button>
        </Box> }
      </Box>
      {recipeData &&
            <Box sx={ { display:'flex' } }>
              {!isEditingName ? (
                <Typography
                  onClick={ ()=>{
                    if(role === 'editor')
                      setIsEditingName(true);
                  } }
                >
                  {newRecipeName}
                </Typography>
              ):(
              // <Typography>Renamin</Typography>
                <TextField
                  variant="standard"
                  ref={ nameRef }
                  value={ newRecipeName }
                  onBlur={ handleRecipeNameBlur }
                  onKeyDown={ handleRecipeNameEnter }
                  fullWidth
                  onChange={ handleRecipeNameChange }
                  className="flow-navbar-name-input"
                />
              )
              }
              {role === 'editor' && <Box sx={ { display: 'flex', alignItems: 'center', ml:1, transition: 'opacity 140ms ease-in-out', opacity:isSaving?.5:0 } }>
                <i className="fa-solid fa-clock-rotate-left fa-spin fa-spin-reverse fa-2xs"></i>
                <Typography sx={ {
                  ml: 0.5,
                  fontSize:'12px',
                } }
                >
                        Saving
                </Typography>
              </Box>}
              {role !== 'editor' && <Box sx={ { display: 'flex', alignItems: 'center', ml:1, opacity:.5 } }>
                <Typography sx={ {
                  ml: 0.5,
                  fontSize:'12px',
                } }
                >
                        Read Only
                </Typography>
              </Box>}
            
            </Box>}
      <Box sx={ { display:'flex', flexDirection:'row', alignItems:"center" } }>
        {role === 'editor' &&
          <>
            <Box sx={ { mr:1 } }>
              <Tooltip title={ isLinkCopied ? 'Link Copied':'Copy Shareable link' } arrow>
                <Button variant="contained" size="small" sx={ { height:'auto' } } className="share-button" color="weavy_cta_secondary" onClick={ handleShareLink }>
                  <i className="fa-solid fa-link"></i>&nbsp;Share
                </Button>
              </Tooltip>
            </Box>
            {fakeDesignAppFlag && <Box>
              <Button 
                variant="contained" 
                size="small" 
                color="weavy_cta" 
                sx={ { height:'auto' } } 
                className="share-button"
                onClick={ ()=> openDesignApp(true) }
                >
                Design App
              </Button>
            </Box>}
          </>
          
        }
        <Box id="flow-navbar-plans-and-credits-container" sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
          <Box id="flow-navbar-credits-container"
            sx={ {
              background:color.Yambo_Black_Dark,
              border:`1px solid ${color.Yambo_Idle_Icon}`,
              borderRadius:4,
              px:1,
              py:.2,
              mx:1,
              display:'flex',
              flexDirection:'row',
              alignItems:"center",
              justifyContent:'center',
            } }
          >
            {/* <Typography variant="caption" sx={ { color:`white !important` } }>
              <i className="fa-solid fa-crown menu-icon" style={ { marginRight:'8px', color:`white !important` } }></i>
            </Typography> */}
            {credits !== null && credits !== undefined ? 
              <>
              <Typography variant="caption" sx={ { color:`white !important` } }>{credits} {credits !== 1? `Credits`:`Credit`}</Typography> 
              </>
              : 
              <Skeleton variant="text" width={ 50 } 
              />
            }
            
          </Box>
          <Link variant="caption" onClick={()=>openUpgradeModal()} sx={{mr:2}}>Get more</Link>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={ { vertical: 'top', horizontal: 'center' } }
        open={ isLinkCopied }
        onClose={ () => setIsLinkCopied(false) }
        autoHideDuration={ 500 }
        TransitionComponent={ SlideTransition }
      >
        <Alert
          severity="success"
          variant="filled"
          sx={ { width: '100%', color: color.Yambo_Text_On_Dark, backgroundColor: color.Yambo_Light_Green } }
        >
                     Link Copied to Clipboard!
        </Alert>
      </Snackbar>

    </Box>
  );
}

export default FlowNavbar;