import React, { useEffect, useState } from "react";
import { Box, Input } from "@mui/material";
import { useUserRole } from "../../Recipe/UserRoleContext";


function NumberCore({ id, data, updateNodeData }) {

  const role = useUserRole();

  const [number, setNumber] = useState(data.output ?? 0);


  useEffect(()=>{
    setNumber(data.result);
  },[data?.result]);

  useEffect(()=>{
    updateNodeData(id, { result:number,output:number });
  }, [number]);

  return (
    <Box sx={ { width:'100%',
      pointerEvents: role === 'guest' ? 'none' : '',
    } }
    >
      <Input
        inputProps={ { type: 'number' } }
        value={ number }
        onChange={ (event)=>{
          const value = parseFloat(event.target.value);
          setNumber(isNaN(value) ? 0 : value);
        } }
      />
    </Box>

  );
}

export default NumberCore;
