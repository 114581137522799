import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { color } from "../../../../colors";
import Photopea from "./photopea/Photopea";
import Inpainter from "./Inpainter";


function Loader ({ offset }) {
    
  return (
    <Box
      sx={
        {
          zIndex:100,
          background:color.Dark_Blue,
          opacity:'.8' ,
          display:'flex',
          width:'100%',
          height:'100%',
          position:'absolute',
          top:0,
          left:0,
          alignItems:'center',
          justifyContent:'center',
          border:`1px solid`,
          borderColor:color.Dark_Grey,
        }
      }
    >
      <Box sx={ { left:`${offset/2}px`, position:'relative', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' } }>
        {/* <Typography variant="caption">Loading</Typography> */}
        <CircularProgress color="inherit" />
      </Box>
    </Box>
  );
}


function Editor ({ editorData, onClose }) {

  const { data, type, setResult } = editorData;
  const { input, handles } = data;

  const [srcFileUrl] = useState(input[handles.input[0]].url || "");
  const [psdFileUrl] = useState(data.result?.url || null);
  const [resultFile, setResultFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    if(resultFile){
      setResult(resultFile);
    }
  },[resultFile]);

  return (
    <>
      <Box id="editor-container"
        sx={ {
          width:'100%',
          height:'100%',
          borderTop:'1px solid',
          borderColor:color.Dark_Grey,
          backgroundColor:color.Dark_Blue,
        } }
      >
        {type === "photopea" &&
            <Photopea
              src={ srcFileUrl }
              result={ resultFile }
              setResult={ setResultFile }
              onClose={ onClose }
              setIsLoading={ setIsLoading }
              psdSrc={ psdFileUrl }
            />
        }
        {type === "inpainter" &&
            <Inpainter
              src={ srcFileUrl }
              result={ resultFile }
              setResult={ setResultFile }
              onClose={ onClose }
              setIsLoading={ setIsLoading }
            />
        }
      </Box>
      {isLoading && <Loader />}
    </>
            
  );
}

export default Editor;