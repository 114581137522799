export const I18N_KEYS = {
  PAYMENTS: {
    SUCCESS_MESSAGE: 'PAYMENTS_SUCCESS_MESSAGE',
  },
  NAVIGATION_DRAWER: {
    BUY_MORE: 'NAVIGATION_DRAWER_BUY_MORE',
  },
  DASHBOARD_BANNER: {
    MESSAGE: 'DASHBOARD_BANNER_MESSAGE',
    CTA: 'DASHBOARD_BANNER_CTA',
  },
  UPGRADE_MODAL: {
    TITLE: 'UPGRADE_MODEL_TITLE',
    CTA: 'UPGRADE_MODEL_CTA'
  },
  MODEL_NODE: {
    ERROR_REQUIRED: 'RUN_MODEL_REQUIRED_ERROR',
    ERROR_IMAGE_INPUT: 'RUN_MODEL_CANNOT_GET_IMAGE_DIMENSIONS',
  },
  RECIPE_MAIN: {
    ERROR_SAVE: 'ERROR_SAVING_RECIPE',
  }
};

export default I18N_KEYS;
