import React, { useCallback, useEffect, useState } from "react";
import { Typography, Box, TextField, Link } from "@mui/material";
import { useUpdateNodeInternals } from "reactflow";
import { colorMap } from "../../colors";
import { useUserRole } from "../Recipe/UserRoleContext";
import { DynamicNode2 } from "./VisualNodes";

function PromptConcat({ id, data, updateNodeData }) {
  const role = useUserRole();
  const { input, handles, description } = data;
  const [combinedPrompt, setCombinedPrompt] = useState("");
  const [additionalPrompt, setAdditionalPrompt] = useState(data.result?.additionalPrompt || "");
  const [isFocused, setIsFocused] = useState(false);
  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(()=>{
    let newCombinedPrompt = "";
    if (input) {
      Object.keys(input).forEach((key) => {
        if (input[key] && typeof input[key] === 'object' && "value" in input[key]) {
          newCombinedPrompt += input[key].value +". ";
        } else if (input[key]) {
          newCombinedPrompt += input[key] +". ";
        }
      });
    }
    setCombinedPrompt(newCombinedPrompt);

    updateNodeData(id, {
      result: {
        additionalPrompt: additionalPrompt,
      },
      output: {
        type:"text",
        prompt: newCombinedPrompt + additionalPrompt,
      },
    });

  },[input, id, additionalPrompt]);

  const onAdditionalPromptChange = (event) => {
    const updatedPrompt = event.target.value;
    setAdditionalPrompt(updatedPrompt);
  };


  const handleAddInputHandle = useCallback(() => {
    const newInput = [...handles.input, `prompt${handles.input.length+1}`];
    updateNodeData(id, {
      handles: {
        ...handles,
        input: newInput,
      },
    });
  }, [handles, updateNodeData, updateNodeInternals]);

  useEffect(()=>{ // this is to update the node internals when the input handles are exposed
    updateNodeInternals(id);
  },[handles.input]);


  return (
    <DynamicNode2 id={ id } data={ data } className="prompt" handleColor={ colorMap.get(data.color) } backgroundColor={ colorMap.get(data.color) } headerColor={ colorMap.get(data.dark_color) }>
      <Typography variant="caption" sx={ { mb:10 } }>{description}</Typography>
      {/* <Box><Typography variant="caption" sx={{mb:10}}>{id}</Typography></Box> */}
      <Box
        sx={ {
          display: 'flex',
          flexDirection: 'column',
          alignItems: "flex-end",
          width:'100%',
          mt:1,
          cursor: role === "guest" ? 'default' : '',
          pointerEvents: role === "guest" ? 'none' : '',
        } }
      >
        <TextField
          className={ isFocused ? "nowheel nodrag nopan": "" }
          value={ combinedPrompt }
          label="Combined Prompt"
          disabled={ true }
          multiline
          minRows={ 3 }
          fullWidth
          size="small"
          onMouseDown={ (event) => event.stopPropagation() }
          onFocus={ () => {setIsFocused(true);} }
          onBlur={ () => {setIsFocused(false);} }
          id={ `concat1-${id}` }
        />
        <TextField
          className={ isFocused ? "nowheel nodrag nopan": "" }
          sx={ { mt:2 } }
          label="Additional Prompt"
          value={ additionalPrompt }
          onChange={ onAdditionalPromptChange }

          multiline
          rows={ 3 }
          fullWidth
          size="small"
          onMouseDown={ (event) => event.stopPropagation() }
          onFocus={ () => {setIsFocused(true);} }
          onBlur={ () => {setIsFocused(false);} }
          id={ `concat2-${id}` }
        />
      </Box>
      <Box sx={ { mt:1 } }>
        <Link onClick={ handleAddInputHandle } sx={ { cursor: role === "guest" ? 'default' : '' } }>+ Add Another Input</Link>
      </Box>
    </DynamicNode2>
  );
}

export default PromptConcat;