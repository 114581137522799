import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, Grid, IconButton, InputAdornment, Input, Link , Box, Button, Typography, Dialog } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import StarOutline from "@mui/icons-material/StarOutline";
import StarIcon from '@mui/icons-material/Star';
import { color } from "../../colors";
import RecipeContextMenu from './RecipeContextMenu';
import DashboardListView from "./DashboardListView";
import ShowCaseRecipesList from "./ShowCaseRecipesList";


function DashboardMain ({ user, error ,showCase, recipes, createRecipe, duplicateRecipe, deleteRecipe , renameRecipe, cancelRename, goToRecipe }) {

  const [hoveredCardId, setHoveredCardId] = useState(null);
  const [newFileCardHovered, setNewFileCardHovered] = useState(false);
  const [favoriteRecipes, setFavoriteRecipes] = useState({});
  const [isListView, setIsListView] = useState(() => {
    const saved = localStorage.getItem('isListView');
    
    return saved !== null ? JSON.parse(saved) : false;
  });

  const [editingRecipe, setEditingRecipe] = useState({ id: null, name: "" });
  const [oldRecipeName, setOldRecipeName] = useState({ id:null, name:"" });
  const [isShowingDeleteDialog, setIsShowingDeleteDialog] = useState({ show:false, id:null });

  const [contextMenu, setContextMenu] = useState({ mouseX: null, mouseY: null, isOpen: false, recipeId:null });


  useEffect(() => {
    localStorage.setItem('isListView', JSON.stringify(isListView));
  }, [isListView]);

  const formattedTime = (time) => {
    return moment(time).fromNow();
  };

  const handleMouseEnterCard = (id) => {
    setHoveredCardId(id);
  };

  const handleMouseLeaveCard = () => {
    setHoveredCardId(null);
  };

  const toggleFavorite = (id, event) => {
    event.stopPropagation();
    setFavoriteRecipes((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleContextMenu = (event, id) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      isOpen: true,
      recipeId:id,
    });
  };

  const handleContextMenuClose = () => {
    setContextMenu({ ...contextMenu, isOpen: false });
  };

  const handleDuplicateRecipe = (id) => {
    // console.log("duplicating recipe", id);
    duplicateRecipe(id);
  };
  ///// RENAME RECIPE
  const enableRecipeNameEdit = (id) => {
    const recipeToRename = recipes.filter((rcp) => rcp.id === id);
    if (recipeToRename) {
      setEditingRecipe({ id: id, name: recipeToRename[0].name || "" });
      setOldRecipeName({ id:id, name:recipeToRename[0].name });
    }
  };
  const handleRenameRecipe = (newName, id) => {
    setEditingRecipe({ id: null, name: "" });
    renameRecipe(newName, id);
  };

  const handleCancelRenameRecipe = (id)=>{
    console.log("Esc pressed");
    cancelRename(oldRecipeName.name, id);
    setEditingRecipe({ id: null, name: "" });
        
  };

  const handleDeleteRecipe = (id) => {
    setIsShowingDeleteDialog({ show:true, id:id });
    // deleteRecipe(id);
  };

  const handleDeleteDialogClose = () => {
    setIsShowingDeleteDialog({ show:false, id:null });
  };

  const handleConfirmDeleteRecipe = () => {
    deleteRecipe(isShowingDeleteDialog.id);
    setIsShowingDeleteDialog({ show:false, id:null });
  };

  const handleCancelDeleteRecipe = () => {
    setIsShowingDeleteDialog({ show:false, id:null });
  };

  const menuItems = [
    { label: 'Open', onClick: (e) => {e.preventDefault();goToRecipe(contextMenu.recipeId, false); handleContextMenuClose(); } },
    { label: 'Open in a new tab', onClick: () => {goToRecipe(contextMenu.recipeId, true); handleContextMenuClose();} },
    'divider',
    { label: 'Duplicate', onClick: () => {handleDuplicateRecipe(contextMenu.recipeId); handleContextMenuClose();} },
    { label: 'Rename', onClick: (e) => {e.preventDefault();enableRecipeNameEdit(contextMenu.recipeId); handleContextMenuClose();} },
    'divider',
    { label: 'Delete', onClick: () => {handleDeleteRecipe(contextMenu.recipeId); handleContextMenuClose();} },
    // Add more menu items here
  ];

  useEffect(() => {
    console.log("error: ", error);
  }, [error]);

  return (
    <>
      <Box component="main" sx={ { width:'100%', height:recipes && recipes.length === 0? "100vh":"", display:'flex', justifyContent:'center', flexDirection:'column', p:8, pb:0, overflowX:'hidden' } }>
        <Box id="showcase-recipes-container" sx={ { width:'100%', mb:2 } }>
          <ShowCaseRecipesList recipes={ showCase } />
        </Box>
        {!error && recipes && recipes.length === 0 &&
                <Box sx={ { width:'100%', height:"100%", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column" } }>
                  <Typography variant="body1" sx={ { mb:1 } }>Nothing to Weave Yet...</Typography>
                  <img src="/illustrations/files_empty.png" width="300px" style={ { filter:"grayscale(1)" } } />
                  <Button variant="outlined" color="weavy_cta_secondary" onClick={ createRecipe } sx={ { mt:1 } } startIcon={ <AddIcon /> }>Create Your First File</Button>
                </Box>
        }
        {error &&
              <Box sx={ { height:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column' } }>
                <i className="fa-regular fa-face-frown" style={ { fontSize:'30px' } }></i>
                <Typography variant="body" color="error" sx={ { mt:2 } }>Oops... Something went wrong!</Typography>
                <Typography variant="body" color="error"><Link onClick={ ()=> window.location.reload() }>Please refresh the page</Link></Typography>
              </Box>
        }
        {recipes && recipes.length > 0 &&
              <Box id="dashboard-top-menu-container" sx={ { display:'flex',width:'100%',justifyContent: 'space-between', alignItems:'center' } }>
                <Typography variant="h2">Recent</Typography>
                <Box id='dashboard-list-top-menu' sx={ { display:'flex', justifyContent:'flex-end', width:'100%', alignContent:'center' } }>
                  <IconButton size="small" onClick={ ()=>setIsListView(true) } sx={ { backgroundColor:isListView ? color.Super_Light_Transparent:'', p:1 } }>
                    <i className="fa-solid fa-list"></i>
                  </IconButton>
                  <IconButton size="small" onClick={ ()=>setIsListView(false) } sx={ { backgroundColor:!isListView ? color.Super_Light_Transparent:'', p:1 } }>
                    <i className="fa-solid fa-grip"></i>
                  </IconButton>
                </Box>
              </Box>}
        {!error && recipes && recipes.length > 0 &&
            <Box id="dashboard-recipes-container" sx={ { mt:2, height:"100%" } }>
              {!isListView && <Grid container  spacing={ 2 }>
                <Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 4 } >
                  <Card sx={ { transition:'transform 0.2s ease-out', maxWidth: 345 } } onMouseEnter={ ()=>{setNewFileCardHovered(true);} }
                    onMouseLeave={ ()=>{setNewFileCardHovered(false);} }
                  >
                    <CardActionArea sx={ { position:'relative' } } onClick={ ()=>createRecipe() } >
                      <Box
                        sx={ {
                          overflow: 'hidden',
                          border: '1px dashed',
                          borderRadius: 2,
                          borderColor:newFileCardHovered ? color.Yambo_Purple : color.Dark_Grey,
                          // boxShadow:  hoveredCardId === recipe.id ? '10px 0px 20px 20px #00F':'none',
                          height: 240,
                          display:'flex',
                          justifyContent:'center',
                          alignItems:'center',
                          flexDirection:'column',
                        } }
                      >
                        <Box sx={ { textAlign:'center' } }>
                          <i className="fa-solid fa-xl fa-plus"
                            style={ {
                              paddingTop:'22px',
                              paddingBottom:'22px',
                              paddingRight:'15px',
                              paddingLeft:'15px',
                              background: newFileCardHovered?  color.Yambo_Purple: color.Light_Grey,
                              borderRadius:'50%',
                              transition: 'background .2s',
                            } }
                          ></i>
                          <Typography variant="body2"
                            sx={ {
                              mt:2,
                              color:newFileCardHovered?'white':color.Light_Grey,
                              transition: 'color .2s',
                            } }
                          >
                                  Create new File
                          </Typography>
                        </Box>
                              
                      </Box>
                    </CardActionArea>
                  </Card>
                </Grid>
                {recipes && recipes
                  .sort((a, b) => {
                    const updated_at_A = a.updated_at || a.createdAt;
                    const updated_at_B = b.updated_at || b.createdAt;
                    
                    return new Date(updated_at_B) - new Date(updated_at_A);
                  })
                  .filter((rcp)=> rcp.owner === user.uid).map((recipe)=>(
                    <Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 4 } key={ recipe.id }  onContextMenu={ (e) => handleContextMenu(e, recipe.id) }>
                      <Card sx={ { transition:'transform 0.2s ease-out', maxWidth: 345 } } onMouseEnter={ () => handleMouseEnterCard(recipe.id) }
                        onMouseLeave={ handleMouseLeaveCard }
                      >
                        <CardActionArea sx={ { position:'relative' } } onClick={ ()=>goToRecipe(recipe.id) } >
                          <Box
                            sx={ {
                              overflow: 'hidden',
                              border: '1px solid',
                              borderRadius: 2,
                              borderColor: hoveredCardId === recipe.id ? color.Yambo_Purple : color.Dark_Grey,
                              // boxShadow:  hoveredCardId === recipe.id ? '10px 0px 20px 20px #00F':'none',
                              height: 240,
                              '&:hover img': {
                                transform: 'scale(1.02)',
                              },
                            } }
                          >
                            <CardMedia
                              component="img"
                              height="100%"
                              image={ recipe.poster || '/empty.png' }
                              alt="recipe poster"
                              sx={ {
                                transition: 'transform 0.12s ease-out', // Smooth transition for the transform
                              } }
                            />
                          </Box>
                          {hoveredCardId === recipe.id && (
                            <Box sx={ { position: 'absolute', top: 10, right: 10 } }>
                              {favoriteRecipes[recipe.id] ?
                                <StarIcon sx={ { color:color.Yellow } } onClick={ (e) => toggleFavorite(recipe.id, e) } /> :
                                <StarOutline  onClick={ (e) => toggleFavorite(recipe.id, e) } />}
                            </Box>
                          )}

                        </CardActionArea>
                        <CardContent sx={ { py:1, pl: 1, backgroundColor:'transparent', boxShadow:'none', backgroundImage: 'initial' } }>
                          {editingRecipe.id === recipe.id ? (
                            <Box>
                              <Input
                                component="div"
                                            
                                inputRef={ (input) => input && input.focus() }
                                onFocus={ (e) =>
                                  e.currentTarget.setSelectionRange(
                                    0,
                                    e.currentTarget.value.length,
                                  ) }
                                variant="standard"
                                value={ editingRecipe.name }
                                onChange={ (e) => setEditingRecipe((prev) => ({ ...prev, name: e.target.value })) }
                                onKeyDown={ (e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleRenameRecipe(editingRecipe.name, editingRecipe.id);
                                  }
                                  if (e.key === 'Escape'){
                                    e.preventDefault();
                                    handleCancelRenameRecipe(editingRecipe.id);
                                  }
                                } }
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="rename recipe"
                                      onClick={ (e)=>{
                                        e.preventDefault();
                                        handleRenameRecipe(editingRecipe.name, editingRecipe.id);
                                      } }
                                    >
                                      <CheckIcon fontSize="small" color={ color.Green } />
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </Box>
                          ) : (
                            <Typography variant="" component="div">
                              {recipe.name}
                            </Typography>
                          )}
                          <Typography variant="caption" color="text.secondary">
                            {recipe.updated_at? `Last edited ${formattedTime(recipe.updated_at)}` : `Created ${formattedTime(recipe.createdAt)}`}
                          </Typography>
                        </CardContent>
                      </Card>
                   
                    </Grid>
                  ))}
              </Grid>}
              {contextMenu.isOpen &&
                  <RecipeContextMenu
                    mouseX={ contextMenu.mouseX }
                    mouseY={ contextMenu.mouseY }
                    isOpen={ contextMenu.isOpen }
                    onClose={ handleContextMenuClose }
                    menuItems={ menuItems }
                  />}
              {isListView && !error &&
                        <DashboardListView
                          recipes={ recipes ? recipes.filter((rcp)=>rcp.owner === user.uid):[] } // REMOVE THIS WHEN FILTER WILL BE IN THE SERVER
                          formatDate={ formattedTime }
                          contextMenu={ contextMenu }
                          handleContextMenu={ handleContextMenu }
                          onContextMenuClose={ handleContextMenuClose }
                          contextMenuItems={ menuItems }
                          goToRecipe={ goToRecipe }
                          handleRenameRecipe={ handleRenameRecipe }
                          editingRecipe={ editingRecipe }
                          setEditingRecipe={ setEditingRecipe }
                          user={ user }
                          cancelRename={ cancelRename }
                        />
              }

            </Box>}

      </Box>
      <Dialog onClose={ handleDeleteDialogClose } open={ isShowingDeleteDialog.show }>
        <Box sx={ { display:'flex', flexDirection:'column', p:4 } }>
          <Typography>Are you sure you want to delete this file?</Typography>
          <Box sx={ { display:'flex', flexDirection:'row', mt:2, justifyContent:'flex-end' } }>
            <Button onClick={ handleCancelDeleteRecipe } variant="contained" color="secondary" sx={ { mr:1 } }>Cancel</Button>
            <Button onClick={ handleConfirmDeleteRecipe }>Yes, Delete</Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default DashboardMain;