import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { color } from "../../../colors";
import { NodeImageList } from "../../Nodes/Utils";
import { useMediaGalleryContext } from "./MediaGalleryContext";


function MediaGallery () {
  const { mediaArray, setShowGallery, selectedFile, setSelectedFile } = useMediaGalleryContext();

  const handleCloseGallery = () => {
    setShowGallery(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' || event.code === 'Space') {
        event.preventDefault();
        handleCloseGallery();
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Box
      id='media-gallery-container'
      sx={ {
        width:'100%',
        height:'100%',
        background:`${color.Yambo_BG}DD`,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position: 'relative',
        overflowX:'hidden',
      } }
      onClick={ handleCloseGallery }
    >
      <NodeImageList images={ mediaArray } selected={ selectedFile } setSelected={ setSelectedFile } container={ 'gallery' } />
      <Box sx={ { height:'100%' } }>
        <IconButton onClick={ handleCloseGallery } sx={ { position:'absolute', right:20, top: 20 } }>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default MediaGallery;