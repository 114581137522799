import React from "react";
import { Box, Button } from "@mui/material";

function TempSignin ({ signIn }) {
  return(
    <Box sx={ { width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center' } }>
      <Button variant='outlined' id="signin-button" onClick={ ()=>signIn("/") } >
                Sign in
      </Button>
    </Box>
  );
}

export default TempSignin;